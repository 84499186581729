import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/banana1.jpg";
import Image2 from "./images/banana2.jpg";
import Image3 from "./images/banana3.jpg";
import Image4 from "./images/banana4.jpg";
import Image5 from "./images/banana5.jpg";
import Image6 from "./images/banana6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const bananaBenefits = [
  {
    text: "Rich in Nutrients",
    details:
      "High in vitamin C, B6, potassium, and magnesium for overall health.",
  },
  {
    text: "Natural Energy Boost",
    details: "Provides quick, easily digestible energy from natural sugars.",
  },
  {
    text: "Promotes Digestive Health",
    details:
      "High in fiber, supporting regular bowel movements and gut health.",
  },
  {
    text: "Supports Heart Health",
    details:
      "Potassium helps regulate blood pressure and supports cardiovascular function.",
  },
  {
    text: "Aids in Weight Management",
    details:
      "Naturally sweet and filling, making them a great snack for controlling cravings.",
  },
];

const BananaPage = () => (
  <Layout>
    <Seo title="Sugar Bananas" />
    <Breadcrumbs title="Sugar Bananas" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 100%; height: 200px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 100%; height: 200px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .farm-description { flex-direction: column; }
                .farm-description img { margin-bottom: 10px; }
                .custom-table th, .custom-table td { padding: 6px; }
                .narrow-paragraph p { font-size: 14px; }
              }

              @media (max-width: 576px) {
                .row > .col-4, .col-md-4 { flex: 0 0 100%; max-width: 100%; }
                .fixed-size { width: 100%; height: auto; }
                .container { padding-left: 15px; padding-right: 15px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Banana field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Banana field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Sugar bananas, also known as baby bananas or manzano
                    bananas, are a smaller variety of banana with a distinctly
                    sweet and creamy flavor. Their smaller size and rich taste
                    make them a favorite among those who enjoy a natural,
                    healthier alternative to traditional dessert options. These
                    bananas are typically a little firmer than regular bananas,
                    and they are known for their bright yellow skin and
                    delightful sweetness.
                  </p>
                  <p>
                    Sugar bananas are often grown in tropical climates, and when
                    you choose organic sugar bananas, you’re ensuring you’re
                    enjoying them in their purest form—without the use of
                    harmful pesticides or chemicals. Organic sugar bananas are
                    cultivated without synthetic chemicals, fertilizers, or
                    pesticides. This means you’re not only avoiding harmful
                    substances but also supporting a more sustainable way of
                    farming. Organic farming methods focus on maintaining soil
                    health, protecting biodiversity, and reducing the carbon
                    footprint of food production.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>90 kcal</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>1.1 g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0.3 g</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>23.1 g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>2.6 g</td>
                  </tr>
                  <tr>
                    <td>Sugars</td>
                    <td>12.2 g</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>15% of DV</td>
                  </tr>
                  <tr>
                    <td>Vitamin B6</td>
                    <td>20% of DV</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>358 mg</td>
                  </tr>
                  <tr>
                    <td>Magnesium</td>
                    <td>27 mg</td>
                  </tr>
                  <tr>
                    <td>Calcium</td>
                    <td>5 mg</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Sugar Bananas</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {bananaBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default BananaPage;
